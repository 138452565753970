var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-playlist-group" }),
        _vm._v(_vm._s(_vm.$t("New Playlist Group")) + " ")
      ]),
      _c(
        "v-card",
        { staticClass: "mt-4 rounded-t-xl round-16 px-6 py-3" },
        [
          _c("v-card-title", { staticClass: "sub-title font-weight-bold" }, [
            _vm._v(_vm._s(_vm.$t("NEW GROUP")))
          ]),
          _c("v-card-text", [
            _c("div", { staticClass: "d-flex align-baseline" }, [
              _c(
                "div",
                { staticClass: "d-inline-flex" },
                [
                  _c("v-select", {
                    staticClass: "form-select",
                    staticStyle: { width: "250px" },
                    attrs: {
                      label: _vm.$t("Model") + " *",
                      items: _vm.defaultModel,
                      outlined: "",
                      dense: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.form.model,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "model", $$v)
                      },
                      expression: "form.model"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "form-input ml-3",
                    staticStyle: { width: "150px" },
                    attrs: {
                      "persistent-placeholder": "",
                      placeholder: _vm.$t("Input title"),
                      label: _vm.$t("Title") + " *",
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "form-input ml-3",
                    staticStyle: { width: "150px" },
                    attrs: {
                      "persistent-placeholder": "",
                      placeholder: _vm.$t("Input category"),
                      label: _vm.$t("Category"),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.form.category,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "category", $$v)
                      },
                      expression: "form.category"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn w-110",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.insertPlaylist()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn w-110 btn-danger ml-3",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelNewPlaylist()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")) + " ")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bg-basic pa-9" },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "playlist-group-time",
                      attrs: { cols: "5" }
                    },
                    [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c(
                          "div",
                          { staticClass: "mr-8" },
                          [
                            _c("v-slider", {
                              staticClass: "playslider-vertical",
                              attrs: { vertical: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", small: "" },
                                          on: { click: _vm.decrement }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/img/ico-play-up.png")
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "prepend",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "" },
                                          on: { click: _vm.increment }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/img/ico-play-down.png")
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ]),
                              model: {
                                value: _vm.timeSlider,
                                callback: function($$v) {
                                  _vm.timeSlider = $$v
                                },
                                expression: "timeSlider"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "playlist-group-timeline" },
                          [
                            _c(
                              "perfect-scrollbar",
                              {
                                staticStyle: {
                                  height: "418px",
                                  "padding-right": "16px"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "playlist-timeline" },
                                  [
                                    _c(
                                      "ul",
                                      _vm._l(24, function(time) {
                                        return _c(
                                          "li",
                                          {
                                            key: time.index,
                                            style:
                                              time < 24
                                                ? {
                                                    height:
                                                      _vm.time_line_height +
                                                      "px",
                                                    position: "relative"
                                                  }
                                                : { height: "initial" }
                                          },
                                          _vm._l(6, function(minute) {
                                            return _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.timeSlider > 50 ||
                                                      minute === 1,
                                                    expression:
                                                      "timeSlider > 50 || minute === 1"
                                                  }
                                                ],
                                                key: minute.index,
                                                style: {
                                                  height:
                                                    _vm.time_line_height / 6 +
                                                    "px",
                                                  width: "100%",
                                                  textAlign: "left"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getTimeText(
                                                        time,
                                                        minute
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "playlist-events" }, [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.events, function(item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass: "playlist-event",
                                          style: {
                                            top:
                                              (_vm.time_line_height / 60) *
                                                item.startTime +
                                              14 +
                                              "px",
                                            height:
                                              (_vm.time_line_height / 60) *
                                                (item.endTime -
                                                  item.startTime) +
                                              "px"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              class: item.active,
                                              style: { "font-size": "3px" },
                                              attrs: { href: "" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.onClickItem(index)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "em",
                                                {
                                                  staticClass:
                                                    "playlist-event-name"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.text) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "mt-6 d-flex justify-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn btn-s",
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialog = true
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Playlist Add")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn btn-s ml-3",
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.deletePlaylist()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Playlist Delete")) + " ")]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "margin-left": "3%",
                        "max-width": "calc(58.3333333333% - 3%)"
                      },
                      attrs: { cols: "7" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "playlist-group-preview" },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "video-preview video-detail",
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "preview-normal-title font-weight-bold"
                                },
                                [_vm._v(_vm._s(_vm.$t("PLAYLIST DETAIL")))]
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "text-center pb-0 pt-3" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "px-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "img-preview" },
                                        [
                                          _vm.playlistPreview
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "row",
                                                  staticStyle: { width: "100%" }
                                                },
                                                _vm._l(
                                                  _vm.selectItem.division
                                                    .division,
                                                  function(item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        style: {
                                                          width: item + "%"
                                                        }
                                                      },
                                                      [
                                                        _vm.selectItem.division[
                                                          index
                                                        ].length !== 0 &&
                                                        index ===
                                                          _vm.selectedContents
                                                            .index
                                                          ? _c("img", {
                                                              staticStyle: {
                                                                width: "100%",
                                                                height: "50px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.selectItem
                                                                    .division[
                                                                    index
                                                                  ].length === 0
                                                                    ? 0
                                                                    : _vm.srcURL +
                                                                      JSON.parse(
                                                                        _vm
                                                                          .selectItem
                                                                          .division[
                                                                          _vm
                                                                            .selectedContents
                                                                            .index
                                                                        ][
                                                                          _vm
                                                                            .selectedContents
                                                                            .i
                                                                        ]
                                                                          .still_shot
                                                                      )[0]
                                                              }
                                                            })
                                                          : _c("img", {
                                                              staticStyle: {
                                                                width: "100%",
                                                                height: "50px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.selectItem
                                                                    .division[
                                                                    index
                                                                  ].length === 0
                                                                    ? 0
                                                                    : _vm.srcURL +
                                                                      JSON.parse(
                                                                        _vm
                                                                          .selectItem
                                                                          .division[
                                                                          index
                                                                        ][0]
                                                                          .still_shot
                                                                      )[0]
                                                              }
                                                            })
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c("div", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("No Contents"))
                                                )
                                              ])
                                        ]
                                      ),
                                      _c(
                                        "h6",
                                        {
                                          staticClass:
                                            "caption text-left pt-6 pb-2"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Contents List"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "perfect-scrollbar",
                                        { staticStyle: { height: "223px" } },
                                        [
                                          _vm.selectItem.length === 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center justify-center",
                                                  staticStyle: {
                                                    height: "223px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("No Contents")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.isContents
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column line-table"
                                                },
                                                _vm._l(_vm.totalItem, function(
                                                  items,
                                                  i
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: i,
                                                      staticClass:
                                                        "d-flex flex-row line-table-row"
                                                    },
                                                    _vm._l(
                                                      _vm.contents.division,
                                                      function(item, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "d-flex line-table-item",
                                                            style: _vm.contents[
                                                              index
                                                            ][i]
                                                              ? {
                                                                  width:
                                                                    item + "%"
                                                                }
                                                              : {
                                                                  width:
                                                                    item + "%",
                                                                  height: "0px"
                                                                }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                class:
                                                                  _vm
                                                                    .selectedContents
                                                                    .i === i &&
                                                                  _vm
                                                                    .selectedContents
                                                                    .index ===
                                                                    index
                                                                    ? "width-full selected"
                                                                    : "width-full",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectContentsIndex(
                                                                      index,
                                                                      i
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .contents[
                                                                        index
                                                                      ][i]
                                                                        ? _vm
                                                                            .contents[
                                                                            index
                                                                          ][i]
                                                                            .media_name
                                                                        : ""
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                }),
                                                0
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-4 d-flex justify-end px-4" },
                            [
                              _c("media-edit-dialog", {
                                attrs: {
                                  isPlaylist: true,
                                  btnDisabledEditMedia: false,
                                  idx_media: _vm.idx_media,
                                  mediaType: _vm.media_type
                                },
                                on: {
                                  fireResetMedia: function($event) {
                                    return _vm.getMediaData()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "1080" },
          on: { "click:outside": _vm.closeDialog },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-playlist-group" }),
                _vm._v(_vm._s(_vm.$t("Playlist Add")) + " ")
              ]),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    { staticClass: "pb-2", attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", { staticClass: "d-flex" }, [
                          _c(
                            "div",
                            { staticStyle: { width: "100%" } },
                            [
                              _c("v-autocomplete", {
                                staticClass:
                                  "form-select form-active scrollBox",
                                attrs: {
                                  disabled: _vm.form.model === 29,
                                  items: _vm.divisions,
                                  label: _vm.$t("Division"),
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.division.division,
                                  callback: function($$v) {
                                    _vm.$set(_vm.division, "division", $$v)
                                  },
                                  expression: "division.division"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-inline-flex align-center ml-9" },
                            [
                              _c("v-text-field", {
                                staticClass: "form-input w-65px text-center",
                                attrs: {
                                  oninput:
                                    "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                  label: _vm.$t("Time"),
                                  counter: "",
                                  maxlength: "2",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.inputValue($event, "startHour")
                                  }
                                },
                                model: {
                                  value: _vm.times.startHour,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.times,
                                      "startHour",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "times.startHour"
                                }
                              }),
                              _c("span", { staticClass: "pt-6 px-2" }, [
                                _vm._v(":")
                              ]),
                              _c("v-text-field", {
                                staticClass: "form-input w-65px text-center",
                                attrs: {
                                  oninput:
                                    "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                  counter: "",
                                  maxlength: "2",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.inputValue($event, "startMinute")
                                  }
                                },
                                model: {
                                  value: _vm.times.startMinute,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.times,
                                      "startMinute",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "times.startMinute"
                                }
                              }),
                              _c("span", { staticClass: "pt-6 px-4" }, [
                                _vm._v("~")
                              ]),
                              _c("v-text-field", {
                                staticClass: "form-input w-65px text-center",
                                attrs: {
                                  oninput:
                                    "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                  counter: "",
                                  maxlength: "2",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.inputValue($event, "endHour")
                                  }
                                },
                                model: {
                                  value: _vm.times.endHour,
                                  callback: function($$v) {
                                    _vm.$set(_vm.times, "endHour", _vm._n($$v))
                                  },
                                  expression: "times.endHour"
                                }
                              }),
                              _c("span", { staticClass: "pt-6 px-2" }, [
                                _vm._v(":")
                              ]),
                              _c("v-text-field", {
                                staticClass: "form-input w-65px text-center",
                                attrs: {
                                  oninput:
                                    "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                  counter: "",
                                  maxlength: "2",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.inputValue($event, "endMinute")
                                  }
                                },
                                model: {
                                  value: _vm.times.endMinute,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.times,
                                      "endMinute",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "times.endMinute"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-4 justify-space-between",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-subheader",
                            { staticClass: "sub-title2 px-0" },
                            [_vm._v(_vm._s(_vm.$t("Playlist Contents")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", column: "" } },
                            [
                              _c("v-row", { attrs: { "no-gutters": "" } }, [
                                _c(
                                  "div",
                                  { staticClass: "playlist-cont-head" },
                                  _vm._l(_vm.division.division, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        style: { width: item + "%" }
                                      },
                                      [
                                        _vm.division[index].length > 0
                                          ? _c("div", {
                                              style: {
                                                width: "100%",
                                                height: "35px",
                                                "background-image":
                                                  "url(" +
                                                  _vm.getMediaPreview(
                                                    _vm.division[index][0]
                                                  ) +
                                                  ")",
                                                "background-size": "100% 100%"
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]),
                              _c(
                                "v-row",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "perfect-scrollbar",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        height: "300px"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "playlist-cont-body" },
                                        _vm._l(_vm.division.division, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              class:
                                                _vm.selectDivision === index
                                                  ? "active"
                                                  : "",
                                              style: {
                                                width: item + "%",
                                                height: "100%",
                                                "min-height": "292px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  if (
                                                    $event.target !==
                                                    $event.currentTarget
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.onClickDivision(
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "ul",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      if (
                                                        $event.target !==
                                                        $event.currentTarget
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.onClickDivision(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.division[index],
                                                  function(media, i) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: i,
                                                        staticStyle: {
                                                          width: "90%"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "center",
                                                              width: "100%"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  media.media_name
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  media.media_type ===
                                                                    "image"
                                                                    ? media.duration +
                                                                        "s"
                                                                    : ""
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              "x-small": "",
                                                              color: "#e27070"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteMedia(
                                                                  index,
                                                                  i
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-close-circle"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticStyle: {
                            "margin-left": "40px",
                            "max-width": "calc(58% - 40px)"
                          },
                          attrs: { cols: "7" }
                        },
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "tabs-type tabs-small",
                              attrs: {
                                "background-color": "transparent",
                                "hide-slider": "",
                                "center-active": "",
                                "active-class": "activeTab"
                              },
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                {
                                  staticClass: "primary--text",
                                  attrs: { href: "#tab-1st" }
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.$t("VIDEO")))])]
                              ),
                              _c(
                                "v-tab",
                                {
                                  staticClass: "primary--text tab-divider",
                                  attrs: { href: "#tab-2nd" }
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.$t("IMAGE")))])]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            { staticClass: "rounded-0 tabs-type-body" },
                            [
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.tab,
                                    callback: function($$v) {
                                      _vm.tab = $$v
                                    },
                                    expression: "tab"
                                  }
                                },
                                [
                                  _vm.tab === "tab-1st"
                                    ? _c(
                                        "v-tab-item",
                                        {
                                          attrs: {
                                            value: "tab-1st",
                                            transition: false,
                                            "reverse-transition": false
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticStyle: {
                                                    height: "317px"
                                                  },
                                                  attrs: { flat: "" }
                                                },
                                                [
                                                  _c("v-card-text", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "simple-line-table horizontal-line mx-3"
                                                      },
                                                      [
                                                        _c("v-simple-table", {
                                                          attrs: {
                                                            "fixed-header": "",
                                                            height: "286"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "colgroup",
                                                                      [
                                                                        _c(
                                                                          "col",
                                                                          {
                                                                            attrs: {
                                                                              width:
                                                                                "40%"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "col",
                                                                          {
                                                                            attrs: {
                                                                              width:
                                                                                "40%"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "col",
                                                                          {
                                                                            attrs: {
                                                                              width:
                                                                                "20%"
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "thead",
                                                                      [
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Preview"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Title"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Add"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "tbody",
                                                                      _vm._l(
                                                                        _vm.videoData,
                                                                        function(
                                                                          item,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "tr",
                                                                            {
                                                                              key: index
                                                                            },
                                                                            [
                                                                              index ===
                                                                              0
                                                                                ? _c(
                                                                                    "th",
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          style: {
                                                                                            position:
                                                                                              "fixed",
                                                                                            width:
                                                                                              "150px",
                                                                                            height:
                                                                                              "200px",
                                                                                            "background-image":
                                                                                              "url(" +
                                                                                              _vm.previewImg +
                                                                                              ")",
                                                                                            "background-size":
                                                                                              "contain",
                                                                                            "background-position":
                                                                                              "center"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "th"
                                                                                  ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.mediaPreviewChange(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      style: {
                                                                                        "max-width":
                                                                                          "160px",
                                                                                        "text-overflow":
                                                                                          "ellipsis",
                                                                                        overflow:
                                                                                          "hidden",
                                                                                        "white-space":
                                                                                          "nowrap"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            item.media_name
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        icon:
                                                                                          "",
                                                                                        "x-small":
                                                                                          "",
                                                                                        color:
                                                                                          "#000"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.addContents(
                                                                                            item,
                                                                                            "video"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-plus-circle"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            335123802
                                                          )
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.tab === "tab-2nd"
                                    ? _c(
                                        "v-tab-item",
                                        {
                                          attrs: {
                                            value: "tab-2nd",
                                            transition: false,
                                            "reverse-transition": false
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticStyle: {
                                                    height: "317px"
                                                  },
                                                  attrs: { flat: "" }
                                                },
                                                [
                                                  _c("v-card-text", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "simple-line-table horizontal-line mx-3"
                                                      },
                                                      [
                                                        _c("v-simple-table", {
                                                          attrs: {
                                                            "fixed-header": "",
                                                            height: "286"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "colgroup",
                                                                      [
                                                                        _c(
                                                                          "col",
                                                                          {
                                                                            attrs: {
                                                                              width:
                                                                                "40%"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "col",
                                                                          {
                                                                            attrs: {
                                                                              width:
                                                                                "25%"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "col",
                                                                          {
                                                                            attrs: {
                                                                              width:
                                                                                "20%"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "col",
                                                                          {
                                                                            attrs: {
                                                                              width:
                                                                                "10%"
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "thead",
                                                                      [
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Preview"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Title"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Input Time"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "th",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Add"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "tbody",
                                                                      _vm._l(
                                                                        _vm.imageData,
                                                                        function(
                                                                          item,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "tr",
                                                                            {
                                                                              key: index
                                                                            },
                                                                            [
                                                                              index ===
                                                                              0
                                                                                ? _c(
                                                                                    "th",
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          style: {
                                                                                            position:
                                                                                              "fixed",
                                                                                            width:
                                                                                              "145px",
                                                                                            height:
                                                                                              "50px",
                                                                                            "background-image":
                                                                                              "url(" +
                                                                                              _vm.previewImg +
                                                                                              ")",
                                                                                            "background-size":
                                                                                              "contain",
                                                                                            "background-position":
                                                                                              "center"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "th"
                                                                                  ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.mediaPreviewChange(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      style: {
                                                                                        "max-width":
                                                                                          "100px",
                                                                                        "text-overflow":
                                                                                          "ellipsis",
                                                                                        overflow:
                                                                                          "hidden",
                                                                                        "white-space":
                                                                                          "nowrap"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            item.media_name
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        oninput:
                                                                                          "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                                                                                        dense:
                                                                                          "",
                                                                                        "hide-details":
                                                                                          ""
                                                                                      },
                                                                                      scopedSlots: _vm._u(
                                                                                        [
                                                                                          {
                                                                                            key:
                                                                                              "append-outer",
                                                                                            fn: function() {
                                                                                              return [
                                                                                                _vm._v(
                                                                                                  " s "
                                                                                                )
                                                                                              ]
                                                                                            },
                                                                                            proxy: true
                                                                                          }
                                                                                        ],
                                                                                        null,
                                                                                        true
                                                                                      ),
                                                                                      model: {
                                                                                        value:
                                                                                          item.duration,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "duration",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.duration"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        icon:
                                                                                          "",
                                                                                        "x-small":
                                                                                          "",
                                                                                        color:
                                                                                          "#000"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.addContents(
                                                                                            item,
                                                                                            "image"
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        {
                                                                                          staticStyle: {
                                                                                            transform:
                                                                                              "rotate(45deg)"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "mdi-close-circle"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            1060683031
                                                          )
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.addPlaylist }
                    },
                    [_vm._v(_vm._s(_vm.$t("Add")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.closeDialog }
                    },
                    [_vm._v(_vm._s(_vm.$t("Close")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }